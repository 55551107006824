import { handleResponse } from '../Assets/handleResponse/handleResponse';
import alertify from 'alertifyjs';
import authHeader from './AuthHeaderService';
import { useHistory } from 'react-router-dom';
import { authenticationService } from './AuthService';

function PushToLogin() {
    const history = useHistory();
    history.push("/Login")
}

async function checkSessao() {
    const currentUser = authenticationService.currentUserValue;
    if (!currentUser) {

        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();

    }

    var expiration = Date.parse(currentUser.expiration)
    var date = Date.parse(new Date());
    if (expiration < date) {
        setTimeout(() => {
            window.location.reload();
        }, 500);
        //não está logado, redirecione para a página de login com o url de retorno

        PushToLogin();
    }
}

export default class UsuarioService {

    constructor({ urlBase }) {
        this.urlBase = urlBase;
    }

    async getUsuarioById(id) {
        await checkSessao();
        let resource = "Usuario/" + id;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                if (!result.success) {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result?.mensagem || "Erro ao processar solicitação!");
                }
                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err?.mensagem || "Erro ao processar solicitação!");
                return err.data;
            });

        return response;
    }

    async getUsuarios(search, quantidade, skip, ordenacao) {
        await checkSessao();
        let resource = "Usuario/listar/" + quantidade + "/" + skip + "?search=" + encodeURI(search) + "&ordenacao=" + ordenacao;
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                if (!result.success) {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result?.mensagem || "Erro ao processar solicitação!");
                }
                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err?.mensagem || "Erro ao processar solicitação!");
                return err.data;
            });

        return response;
    }

    async adicionarUsuario(data) {

        await checkSessao();
        let resource = "Usuario/adicionar";
        let url = this.urlBase + resource;

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                if (!result.success) {
                    
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result?.mensagem || "Erro ao processar solicitação!");
                }
                else {
                    
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.warning("Usuário cadastrado com sucesso!");
                }
                return result.data;
            })
            .catch(function (err) {
                
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err?.mensagem || "Erro ao processar solicitação!");
                return err.data;
            });

        
        return response;
    }

    async editarUsuario(usuarioId, dados, empresasSelecionadas) {

        await checkSessao();
        let resource = "Usuario/editar";
        let url = this.urlBase + resource;
        let data = {
            usuarioId: usuarioId,
            nome: dados.nome,
            email: dados.email,
            senhaAntiga: dados.senhaAtual,
            senha: dados.senha,
            perfil: dados.perfilId,
            empresas: empresasSelecionadas.map(e => e.empresaId),
            permiteUpload: dados.permitirUpload
        }
        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                if (!result.success) {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result?.mensagem || "Erro ao processar solicitação!");
                }
                else {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.warning("Usuário editado com sucesso!");
                }
                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err?.mensagem || "Erro ao processar solicitação!");
                return err.data;
            });

        return response;
    }

     async SetAtivarDesativar(operacao, empresaId, usuarioId) {
            await checkSessao();
            let resource = `Usuario/editar-empresas`;
            let url = this.urlBase + resource;
    
            let data = {
                empresaId: empresaId,
                operacao: operacao,
                usuarioId: usuarioId
            }
            var response = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
            })
                .then(handleResponse)
                .then(function (result) {
                    return result.data;
                })
                .catch(function (err) {
                    return err.data;
                });
    
            return response;
        }
    

    async excluirUsuario(userEmail, userId) {

        await checkSessao();
        let resource = "Usuario/excluir";
        let url = this.urlBase + resource;
        let data = {
            email: userEmail,
            usuarioId: userId
        }

        var response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': authHeader() }
        })
            .then(handleResponse)
            .then(function (result) {
                if (!result.success) {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result?.mensagem || "Erro ao processar solicitação!");
                }
                else {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.warning("Usuário excluido com sucesso!");
                }
                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err?.mensagem || "Erro ao processar solicitação!");
                return err.data;
            });

        return response;
    }

    async recuperarSenha(userEmail) {
        let resource = "Usuario/recuperarsenha/";
        let url = this.urlBase + resource + userEmail;

        var response = await fetch(url, {
            method: 'POST',
        })
            .then(handleResponse)
            .then(function (result) {
                if (!result.success) {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.error(result?.mensagem || "Erro ao processar solicitação!");
                }
                else {
                    alertify.set('notifier', 'position', 'bottom-right');
                    alertify.warning("Email enviado com sucesso!");
                }
                return result.data;
            })
            .catch(function (err) {
                alertify.set('notifier', 'position', 'bottom-right');
                alertify.error(err?.mensagem || "Erro ao processar solicitação!");
                return err.data;
            });

        return response;
    }

};